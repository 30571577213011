import { checkBeforeEnter } from '@/auth/utils';

export default [
    {
        path: '/proveedores',
        name: 'providers-list',
        component: () => import('@/views/modules/providers/providers-list/ProvidersList.vue'),
        beforeEnter: (to, from, next) => { checkBeforeEnter(['isSuperAdmin', 'isAdmin'], to, from, next) },
        meta: {
            resource: 'ACL',
            pageTitle: 'Proveedores',
            breadcrumb: [{
                text: 'Lista de proveedores',
                active: true
            }],
            requiresAuth: true
        }
    },
    {
        path: '/proveedores/nuevo',
        name: 'providers-create',
        component: () => import ('@/views/modules/providers/providers-store/ProvidersStore.vue'),
        beforeEnter: (to, from, next) => { checkBeforeEnter(['isSuperAdmin', 'isAdmin'], to, from, next) },
        meta: {
            resource: 'ACL',
            pageTitle: 'Proveedores',
            breadcrumb: [{
				text: 'Lista de proveedores',
				to: { name: 'providers-list' },
			},{
                text: 'Nuevo proveedor',
                active: true
            }],
            requiresAuth: true
        }
    },
    {
        path: '/proveedores/:id/editar',
        name: 'providers-edit',
        component: () => import ('@/views/modules/providers/providers-edit/ProvidersEdit.vue'),
        beforeEnter: (to, from, next) => { checkBeforeEnter(['isSuperAdmin', 'isAdmin'], to, from, next) },
        meta: {
            resource: 'ACL',
            pageTitle: 'Proveedores',
            breadcrumb: [{
				text: 'Lista de proveedores',
				to: { name: 'providers-list' },
			}, {
                text: 'Editar proveedor',
                active: true
            }],
            requiresAuth: true
        }
    }
]