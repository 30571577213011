import { checkBeforeEnter } from '@/auth/utils';

export default [
    {
        path: '/documentos',
        name: 'documents-list',
        component: () => import('@/views/modules/documents/documents-list/DocumentsList.vue'),
        beforeEnter: (to, from, next) => { checkBeforeEnter(['isSuperAdmin', 'isAdmin'], to, from, next) },
        meta: {
            resource: 'ACL',
            pageTitle: 'Documentos',
            breadcrumb: [{
                text: 'Lista de documentos',
                active: true
            }],
            requiresAuth: true
        }
    },
	{
        path: '/documentos/generar/:id',
        name: 'documents-generate',
        component: () => import ('@/views/modules/documents/documents-generate/DocumentsGenerate.vue'),
        beforeEnter: (to, from, next) => { checkBeforeEnter(['isSuperAdmin', 'isAdmin'], to, from, next) },
        meta: {
            resource: 'ACL',
            pageTitle: 'Documentos',
            breadcrumb: [{
				text: 'Lista de documentos',
				to: { name: 'documents-list' },
			}, {
					text: 'Generar documento',
                active: true
            }],
            requiresAuth: true
        }
    }
]