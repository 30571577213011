import { checkBeforeEnter } from '@/auth/utils';

export default [
    {
        path: '/reporteria/evaluaciones-de-criticidad',
        name: 'report-criticality-evaluations',
        component: () => import('@/views/modules/reporters/CriticalityEvaluations.vue'),
        beforeEnter: (to, from, next) => { checkBeforeEnter(['isSuperAdmin', 'isAdmin'], to, from, next) },
        meta: {
            action: 'read',
            resource: 'ACL',
            pageTitle: 'Estadísitcas y Reportería',
            breadcrumb: [{
                text: 'Evaluaciones de Criticidad',
                active: true
            }]
        }
    }, 
    {
        path: '/reporteria/evaluaciones-de-desempeno',
        name: 'report-performance-evaluations',
        component: () => import('@/views/modules/reporters/PerformanceEvaluations.vue'),
        beforeEnter: (to, from, next) => { checkBeforeEnter(['isSuperAdmin', 'isAdmin'], to, from, next) },
        meta: {
            action: 'read',
            resource: 'ACL',
            pageTitle: 'Estadísitcas y Reportería',
            breadcrumb: [{
                text: 'Evaluaciones de Desempeño',
                active: true
            }]
        }
    }, 
]