import { checkBeforeEnter } from '@/auth/utils';

export default [
    {
        path: '/criticidades',
        name: 'criticalities-list',
        component: () => import('@/views/modules/criticalities/criticalities-list/CriticalitiesList.vue'),
        beforeEnter: (to, from, next) => { checkBeforeEnter(['isSuperAdmin', 'isAdmin'], to, from, next) },
        meta: {
            resource: 'ACL',
            pageTitle: 'Criticidades',
            breadcrumb: [{
                text: 'Lista de criticidades',
                active: true
            }],
            requiresAuth: true
        }
    },
    {
        path: '/criticidades/evaluar/:id',
        name: 'criticalities-evaluate',
        component: () => import ('@/views/modules/criticalities/criticalities-evaluate/CriticalitiesEvaluate.vue'),
        beforeEnter: (to, from, next) => { checkBeforeEnter(['isSuperAdmin', 'isAdmin'], to, from, next) },
        meta: {
            resource: 'ACL',
            pageTitle: 'Criticidades',
            breadcrumb: [{
				text: 'Lista de criticidades',
				to: { name: 'criticalities-list' },
			}, {
					text: 'Evaluar criticidad',
                active: true
            }],
            requiresAuth: true
        }
    }
]