import { checkBeforeEnter } from '@/auth/utils';

export default [
    {
        path: '/contratos',
        name: 'contracts-list',
        component: () => import('@/views/modules/contracts/contracts-list/ContractsList.vue'),
        beforeEnter: (to, from, next) => { checkBeforeEnter(['isSuperAdmin', 'isAdmin'], to, from, next) },
        meta: {
            resource: 'ACL',
            pageTitle: 'Contratos',
            breadcrumb: [{
                text: 'Lista de contratos',
                active: true
            }],
            requiresAuth: true
        }
    },
    {
        path: '/contratos/nuevo',
        name: 'contracts-create',
        component: () => import ('@/views/modules/contracts/contracts-store/ContractsStore.vue'),
        beforeEnter: (to, from, next) => { checkBeforeEnter(['isSuperAdmin', 'isAdmin'], to, from, next) },
        meta: {
            resource: 'ACL',
            pageTitle: 'Contratos',
            breadcrumb: [{
				text: 'Lista de contratos',
				to: { name: 'contracts-list' },
			},{
                text: 'Nuevo contrato',
                active: true
            }],
            requiresAuth: true
        }
    },
    {
        path: '/contratos/editar/:id',
        name: 'contracts-edit',
        component: () => import ('@/views/modules/contracts/contracts-edit/ContractsEdit.vue'),
        beforeEnter: (to, from, next) => { checkBeforeEnter(['isSuperAdmin', 'isAdmin'], to, from, next) },
        meta: {
            resource: 'ACL',
            pageTitle: 'Contratos',
            breadcrumb: [{
				text: 'Lista de contratos',
				to: { name: 'contracts-list' },
			}, {
                text: 'Editar contrato',
                active: true
            }],
            requiresAuth: true
        }
    }
]